

<div id="redoc" class="redoc-container">
    <!-- <script src="./node_modules/redoc/bundles/redoc.standalone.js"></script> -->
    <div class="navbar">
      <img src="assets/images/Apptium-logo-Blue_Orange.png" class="logo-image">
        <a href="/assets/API_Reference_Guide_v-0.2.pdf" target="_blank" class="user-guide d-flex">
          <!-- <span class="material-icons">
            expand_more
            </span>  -->
            <img src="assets/images/guide-book.svg" class="guide-book">
            User Guide</a>
    </div> 
  </div>


  
  