import { Component, OnInit } from '@angular/core';

declare var Redoc: any;

@Component({
  selector: 'app-redoc',
  templateUrl: './apidocs.component.html',
  styleUrls: ['./apidocs.component.scss']
})
export class RedocComponent implements OnInit{

  ngOnInit(): void {
    }
}
